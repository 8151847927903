import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { IconButton, LinearProgress } from '@mui/material';
import { getSignedUploadURl, getSignedURltoviewFile } from 'src/services/file';
import axiosInstance from 'src/utils/axios';
import axios from 'axios';
import RejectionFiles from './errors-rejection-files';
import { useLocation } from 'react-router-dom';
import Iconify from '../iconify';
import { fileNameByUrl } from '../file-thumbnail';
import { usePermify } from '@permify/react-role';
import PdfPreviewPopup from '../common/pdfPreview';
import DeleteConfirmationDialog from 'src/sections/CommonHome/DeleteConfirmationDialog';

// ----------------------------------------------------------------------
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function UploadAvatar({
  disabled,
  label,
  helperText,
  sx,
  path,
  setValue,
  fileNameColor,
  ...other
}) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(0);
  const [auth, setAuth] = useState(false);
  const location = useLocation();
  const { isAuthorized, isLoading } = usePermify();
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  useEffect(() => {
    setPage(location?.pathname?.split('/')[2]);
  }, [location]);

  useEffect(() => {
    setAuth(isAuthorized(['admin'], 'quote_backend_page_1_default_start_po'));
  }, []);

  useEffect(() => {
    setError(other.error);
    setFile(other.value);
  }, [other.error, other.value]);

  const handleUpload = async (File) => {
    if (!File) {
      alert('Please select a file to upload');
      return;
    }

    try {
      // Replace 'your-backend-api-endpoint' with the actual endpoint to get the signed AWS URL
      const urlIncoded = File?.name?.split(" ").join("");
      const response = await getSignedUploadURl(`${path}/${urlIncoded}`);

      if (response) {
        const { signedUrl } = response;

        const formData = new FormData();
        formData.append('file', File);

        const uploadResponse = await axios.put(signedUrl, File, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },

          onUploadProgress: (event) => {
            const percentCompleted = (event.loaded / event.total) * 100;
            setProgress(percentCompleted);
          },
        });

        if (uploadResponse.status === 200) {
          setProgress(0);

          setValue(`${path}/${urlIncoded}`);
          // window.open(signedUrl, '_blank');
        } else {
          alert('Failed to upload file');
        }
      } else {
        alert('Failed to get signed URL');
      }
    } catch (er) {
      setProgress(0);
      setError('Error:', error);
      // console.log(er);
      alert('Failed to communicate with the server');
    }
  };

  const onDrop = async (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    handleUpload(selectedFile);
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    disabled: progress > 0,
    // accept: {
    //   'image/*': [],
    // },
    onDrop,
  });

  const hasFile = !!file;
  return (
    <Box>
      <DeleteConfirmationDialog isAlertOpen={isAlertOpen} handleClose={()=> setIsAlertOpen(false)} handleYesClick={()=>{
                setFile(null);
                setValue('');
                setIsAlertOpen(false)
      }} />

      {hasFile ? (
        <Stack alignItems="baseline" direction="row" spacing={1}>
          <Stack direction="column" sx={sx}>
            <PdfPreviewPopup pdfUrl={file} label={`VIEW ${label}`} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="body"
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                fontSize: '12px',
                color: fileNameColor ? fileNameColor:'white',
              }}
            >
              {hasFile && file ? (file?.name ? file?.name : fileNameByUrl(file)) : ''}
            </Typography>
            {!disabled ? (
            <IconButton
              style={{ height: '40px', width: '40px' }}
              onClick={() => {
                // setFile(null);
                // setValue('');
                setIsAlertOpen(true)
              }}
            >
              <Iconify icon="material-symbols:close" sx={{ height: 32 }} />
            </IconButton>
          ) : (
            ''
          )}
          </div>
          </Stack>
          {/* {!disabled ? (
            <IconButton
              style={{ height: '40px', width: '40px' }}
              onClick={() => {
                setFile(null);
                setValue('');
              }}
            >
              <Iconify icon="material-symbols:close" sx={{ height: 32 }} />
            </IconButton>
          ) : (
            ''
          )} */}
        </Stack>
      ) : page === 'l1' && !auth ? (
        <Box>No file</Box>
      ) : (
        <Button
          {...getRootProps()}
          variant="outlined"
          disabled={disabled}
          sx={sx}
          style={{ textTransform: 'uppercase' }}
        >
          <input {...getInputProps()} />
          {file ? `Update ${label}` : `Upload ${label}`}
        </Button>
      )}

      {progress > 0 ? <LinearProgress variant="determinate" value={progress} /> : ''}
      {error ? (
        <Typography
          textTransform="uppercase"
          variant="caption"
          sx={{ color: 'error.main', pl: 2, pt: 1 }}
        >
          {error}
        </Typography>
      ) : (
        ''
      )}
      <RejectionFiles fileRejections={fileRejections} />
    </Box>
  );
}

UploadAvatar.propTypes = {
  disabled: PropTypes.object,
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.object,
  sx: PropTypes.object,
};
