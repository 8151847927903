import axios, { endpoints } from 'src/utils/axios';
// DONE
export const GET_ALL_CLIENT_INFO = (page, pageSize) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.clientInfo.list}?page=${page}&pageSize=${pageSize}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
export const GET_CLIENT_LOGO = (name) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.clientInfo.getLogo}${name}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

// DONE
export const ADD_NEW_CLIENT = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.clientInfo.add}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const UPDATE_CLIENT_DETAILS = (unitId, data) =>
  new Promise((resolve, reject) => {
    axios
      .put(`${endpoints.clientInfo.update}${unitId}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

// DONE
export const DELETE_CLIENT_INFO = (data) =>{

  let requestedOptions = {
    data:data
   };
  new Promise((resolve, reject) => {
    axios
      .delete(`${endpoints.clientInfo.delete}`, requestedOptions)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  })};

  // DONE
export const ADD_NEW_CLIENT_USER = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.clientInfo.addUser}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

  export const GET_ALL_USER_BY_CLIENT = (clientId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${endpoints.clientInfo.getUserList}${clientId}`)
        .then((response) => resolve(response?.data))
        .catch((error) => reject(error));
    });

    export const UPDATE_CLIENT_USER = (id,data) =>
      new Promise((resolve, reject) => {
        axios
          .put(`${endpoints.clientInfo.updateUser}${id}`, data)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
