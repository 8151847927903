// import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  TextField,
  Typography,
  Chip,
  Stack,
  InputAdornment,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CollapsableTable from 'src/components/collapsable-table';
// import Iconify from 'src/components/iconify/iconify';
// import { approveQuote } from 'src/services/vendor';
import { fCurrency } from 'src/utils/format-number';
import CommentsModal from 'src/components/comments/index';
import VendorCurrencyConverter from 'src/layouts/_common/vendor-currency-converter';
import CurrencyConverter from 'src/layouts/_common/currency_converter/eshop_curr_converter';
import CountComment from 'src/components/comments/CountComment';

export default function VendorSelectionTable(props) {
  const { loading, data, baseCurrency } = props || {};
  const [tableData, setTableData] = useState([]);

  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [commentId, setCommentId] = useState(null);

  const handleComments = (row) => {
    setCommentId(row?._id);
    setIsCommentOpen(true);
  };

  useEffect(() => {
    if (data?.length) {
      setTableData(data);
    }
  }, [data, data?.length]);

  const COLUMNS = [
    { id: 'slNo', label: 'SNO.', minWidth: 170 },
    {
      id: 'description',
      label: 'Description',
      maxWidth: 170,
      render: (value, row) => (value ? <Typography sx={{ width: 320 }}>{value}</Typography> : '--'),
    },
    {
      id: 'PN',
      label: 'P/N',
      width: 170,
    },
    {
      id: 'deliveryLeadTime',
      label: 'Delivery Timeline',
      width: 170,
      render: (value, row) => (value ? `${value} ${row?.deliveryLeadTimeUnit}` : '--'),
    },
    {
      id: 'unit',
      label: 'Unit of Issue',
      minWidth: 100,
      render: (value, row) => (
        <Box display="flex" flexDirection="column">
          {value ? value : '--'}
        </Box>
      ),
    },
    {
      id: 'quantity',
      label: 'QUANTITY',
      minWidth: 100,
      render: (value, row) => (
        <Box display="flex" flexDirection="column">
          {value ? value : '--'}
        </Box>
      ),
    },
  ];

  const SECONDARY_COLUMNS = [
    {
      id: 'price',
      label: 'Vendor Unit Price',
      minWidth: 120,
      render: (value, row) => (
        <Box display="flex" flexDirection="column" style={{  paddingTop:10}}>
           { fCurrency(value.price, row?.currency?.symbol) }
        </Box>)
    },
    {
      id: 'type',
      label: 'Type',
      minWidth: 80,
      render: (value) => (
        <Chip
          style={{ textTransform: 'uppercase',  marginTop:10 }}
          color={value.type === 'vendor' ? 'primary' : 'secondary'}
          variant="outlined"
          label={value.type}
        />
      ),
    },
    {
      id: 'VendorName',
      label: 'Vendor Name',
      minWidth: 100,
      render: (value, row) => (
        <Box display="flex" flexDirection="column" style={{  paddingTop:5}}>
          <Typography
            textTransform="uppercase"
            variant="subtitle2"
            component="span"
            sx={{ mx: 0.25, textDecoration: 'underline' }}
          >
            {row.vendorName ? row.vendorName : row?.eshopName ? 
            <Button
                style={{ textTransform: 'uppercase' }}
                // href=""
                onClick={() => window.open(row?.eshopLink)}
                // variant="outlined"
                sx={{ ml: 2, mt: 1 }}
                // startIcon={<Iconify icon="mingcute:add-line" />}
              >
                <u>{row.eshopName}</u>
              </Button>
            : '--'}
          </Typography>
        </Box>
      ),
    },

    {
      id: 'quantity',
      label: 'QTY Selection',
      minWidth: 170,
      render: (values, row, handleUpdateValue, errors) => {
        return (
          <TextField
            sx={{ textTransform: 'uppercase' }}
            disabled={values.isApproved}
            type="number"
          onChange={(e)=>handleUpdateValue('quantityApproved', e.target.value)}
            value={values?.quantityApproved}
            name="quantityApproved"
            error={Boolean(errors.quantityApproved)}
            helperText={errors.quantityApproved || ''}
          />
        );
      },
    },
    {
      id: 'make',
      label: 'Make',
      minWidth: 170,
      render: (value) => <Typography style={{ wordBreak: 'break-word', paddingTop:20 }}>{value.make}</Typography>,
    },
    {
      id: 'price',
      label: 'Purchase Unit Price',
      minWidth: 270,
      render: (value, row, setValue, handleUpdateValue) => (
        <Stack>
          {/* <VendorCurrencyConverter
            readonly={value.isApproved}
            targetCurrency={row?.currency?.abbreviation}
            targetValue={value.price}
            // sourceCurrencyVal={row?.currency.abbreviation}
            sourceCurrencyVal={baseCurrency?.abbreviation}
            // handleUpdateValue={handleUpdateValue}
          /> */}
          <CurrencyConverter
          // title={"Eshop"}
          // fieldName='price'
          // id={row?._id}
          readonly={true}
          targetCurrency={row?.currency?._id}
          targetValue={value?.price}
          // handleUpdateValue={handleUpdateValue}
          // handleUpdateCurrencyPriceValue={handleUpdateCurrencyPriceValue}
          sourceCurrency={row?.purchaseInpCurrencyVR}
          sourceValue={row?.purchaseInpPriceVR}
          disableTargetCurrency={true}
        />
        </Stack>
      ),
    },

    {
      id: 'FinalPrice',
      label: 'Final Price',
      minWidth: 180,
      render: (values, row, handleUpdateValue, errors) => (
        <TextField
          sx={{ textTransform: 'uppercase' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{baseCurrency?.symbol || '$'}</InputAdornment>
            ),
          }}
          disabled={values.isApproved}
          type="number"
          onChange={(e)=>handleUpdateValue('finalPrice', e.target.value)}
          value={values.finalPrice}
          name="finalPrice"
          error={Boolean(errors.finalPrice)}
            helperText={errors.finalPrice || ''}
        />
      ),
    },
    {
      id: 'purchaseTotal',
      label: 'Final Total Price',
      minWidth: 170,
      render: (values, row, setValue) => (
        <Typography variant="h6" sx={{pt:3 }}>{fCurrency(values.finalPrice*values.quantity, baseCurrency.symbol)}</Typography>
      ),
    },
    {},

    {
      id: 'comment',
      label: 'Comment',
      minWidth: 170,
      render: (values, row) => (
        <Box
          onClick={() => {
            handleComments(row);
          }}
          display="flex"
          flexDirection="column"
        >
          <Box sx={{ ml: 1 }}>
            {/* <Iconify width={20} icon="ic:outline-chat" /> */}
            <CountComment projectId={row?._id} isCommentOpen={isCommentOpen} />
          </Box>
          <Typography
            textTransform="uppercase"
            variant="caption"
            component="span"
            sx={{ width: 20 }}
          >
            View/Write Comment
          </Typography>
        </Box>
      ),
    },
    // { id: 'action', label: 'Action' },
  ];

  return (
    <Box>
     <CommentsModal
        isCommentOpen={isCommentOpen}
        setIsCommentOpen={setIsCommentOpen}
        id={commentId}
      />
      <CollapsableTable
        primaryColumns={COLUMNS}
        primaryTableData={tableData}
        secondaryColumns={SECONDARY_COLUMNS}
        baseCurrency={props.baseCurrency}
        loading={loading}
        enableCheckBox={false}
      />
    </Box>
  );
}

// const ApproveRequestComponent = ({ value, isApproved = false, id }) => {
//   const [quantity, setQuantity] = useState(value);
//   const [loading, setLoading] = useState(false);
//   const [isapproved, setApproved] = useState(isApproved);
//   const onClick = () => {
//     setLoading(true);
//     approveQuote(id, quantity)
//       .then((res) => {
//         setLoading(false);
//         setApproved(true);
//       })
//       .catch((err) => {
//         setLoading(false);
//       });
//   };

//   return (
//     <Box display="flex" flexDirection="column">
//       <Box sx={{ mb: 0.3 }} display="flex" flexDirection="row">
//         <Typography textTransform="uppercase" variant="caption" component="span" sx={{ mr: 2.2 }}>
//           Quantity
//         </Typography>
//         <Iconify width={20} icon="ic:outline-edit" />
//       </Box>
//       <TextField
//         disabled={isapproved}
//         size="small"
//         sx={{ mb: 1.2, width: 100 }}
//         type="number"
//         value={quantity}
//         onChange={(e) => setQuantity(e.target.value)}
//       />
//       {isapproved ? (
//         <Chip
//           style={{ textTransform: 'uppercase' }}
//           variant="outlined"
//           color="success"
//           label="Approved"
//         />
//       ) : (
//         <LoadingButton
//           style={{ textTransform: 'uppercase' }}
//           loading={loading}
//           onClick={onClick}
//           variant="contained"
//           sx={{ borderRadius: 0, width: 100 }}
//         >
//           Approve
//         </LoadingButton>
//       )}
//     </Box>
//   );
// };
