import React, { useEffect, useState } from 'react'
import Badge from '@mui/material/Badge';
import { GET_COMMENTS_LIST } from 'src/services/comment';
import CommentIcon from '@mui/icons-material/Comment';

const CountComment = (props) => {
    const { projectId,  isCommentOpen} = props
    const [commentCount, setCommentCount] = useState(0)
    useEffect(() => {
        if (!isCommentOpen) {
          GET_COMMENTS_LIST(projectId, 1, 1000)
            .then((response) => {
                setCommentCount(response?.data.totalRecords)
            })
            .catch((err) => {
                // console.log(err)
            });
        }
      }, [projectId, isCommentOpen]);
    

  return (
    <Badge badgeContent={commentCount} color="primary">
        <CommentIcon style={{ marginLeft:"10px"}}/>
    </Badge>
  )
}

export default CountComment