import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {  Chip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Divider } from '@mui/material';
import {  Typography } from '@mui/material';


export default function DeleteConfirmationDialog({isAlertOpen, handleClose, handleYesClick}) {

  return (
      <Dialog
        open={isAlertOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ textTransform: 'uppercase' }}>{"Confirmation ALERT"}</DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography textTransform="uppercase">Are you sure you want to delete this?</Typography>
        </DialogContentText>
        </DialogContent>
        <Divider sx={{ mt: 1 }} />
        <DialogActions>
          <Button variant="contained" size="small" color="success" onClick={handleYesClick}>
            DELETE
          </Button>
            <Button style={{ textTransform: 'uppercase' }} variant="contained" size="small" onClick={handleClose}>
                  Cancel
            </Button>
        </DialogActions>
      </Dialog>
  );
}