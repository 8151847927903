import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton, LinearProgress } from '@mui/material';
import { getSignedUploadURl, getSignedURltoviewFile } from 'src/services/file';

import axios from 'axios';
import RejectionFiles from './errors-rejection-files';
import Iconify from '../iconify';
import Image from '../image/image';
import PdfPreviewPopup from '../common/pdfPreview';
import DeleteConfirmationDialog from 'src/sections/CommonHome/DeleteConfirmationDialog';

export default function UploadManualFiles({
    deleteSelectedFile,
  disabled,
  label,
  helperText,
  sx,
  path,
  viewmode,
  setValue,
  ...other
}) {
  const [file, setFile] = useState([]);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [filesWithUrl, setFilesWithUrl] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();

  useEffect(() => {
    setError(other.error);
    setFile(other?.value || []);
  }, [other.error, other.value]);

  const handleUpload = async (File) => {
    if (!File) {
      alert('Please select a file to upload');
      return;
    }

    try {
      // Replace 'your-backend-api-endpoint' with the actual endpoint to get the signed AWS URL
      const urlIncoded = File?.name?.split(" ").join("");
      const response = await getSignedUploadURl(`${path}/${urlIncoded}`);

      if (response) {
        const { signedUrl } = response;

        const formData = new FormData();
        formData.append('file', File);

        const uploadResponse = await axios.put(signedUrl, File, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },

          onUploadProgress: (event) => {
            const percentCompleted = (event.loaded / event.total) * 100;
            setProgress(percentCompleted);
          },
        });

        if (uploadResponse.status === 200) {
          setProgress(0);
          let allFiles = [...file];
          allFiles.push(`${path}/${urlIncoded}`);

          setFile(allFiles);
          setValue && setValue(allFiles);
        } else {
          alert('Failed to upload file');
        }
      } else {
        alert('Failed to get signed URL');
      }
    } catch (er) {
      setProgress(0);
      setError('Error:', error);
      // console.log(er);
      alert('Failed to communicate with the server');
    }
  };

  const onDrop = async (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    let allFiles = [...file];
    // allFiles.push(selectedFile);

    setFile(allFiles);
    handleUpload(selectedFile);
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    disabled: progress > 0,
    // accept: {
    //   'image/*': [],
    // },
    onDrop,
  });

  useEffect(() => {
    Promise.all(file?.map((f) => getSignedURltoviewFile(f))).then((fi) => {
      setFilesWithUrl(fi);
    });
  }, [file]);


  return (
    <Box sx={{width:'100%'}}>
      <DeleteConfirmationDialog isAlertOpen={isAlertOpen} handleClose={()=> setIsAlertOpen(false)} handleYesClick={()=>{
            deleteSelectedFile(selectedId)
            setIsAlertOpen(false)
            setSelectedId()
      }} />
      {viewmode ? (
        ''
      ) : (
        <Button
          {...getRootProps()}
          variant="contained"
          sx={sx}
          disabled={disabled}
          style={{ textTransform: 'uppercase', marginTop: '10px' }}
        >
          <input {...getInputProps()} />
          {`Upload ${label}`}
        </Button>
      )}
      {filesWithUrl.map((i, index) => (
        <Stack alignItems="center" direction="row" spacing={1}>
          <Stack
            direction="column"
            // sx={sx}
            style={{ marginTop:"5px", marginLeft:"15px"}}
            // onClick={() => window.open(i, '_blank', 'width=600,height=400')}
          >

                {
                    file?.[index]?.split('/').pop().endsWith('.pdf') ?
                    <PdfPreviewPopup pdfUrl={file?.[index]} label={file?.[index]?.split('/').pop()} />
                    : 
                    <Typography style={{ color:"#C4CDD5"  }} onClick={() => window.open(i, '_blank', 'width=600,height=400')}>
                        {file?.[index]?.split('/').pop()}
                    </Typography>
                }
            
            {/* <Button style={{ textTransform: 'uppercase' }} variant="outlined">
              View {label}
            </Button>
            <Typography variant="body" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              {hasFile && i ? (i?.name ? i?.name : fileNameByUrl(i)) : ''}
            </Typography> */}
          </Stack>
          {disabled ? null:
          <IconButton
              style={{ height: '40px', width: '40px' }}
              onClick={() => {
                // deleteSelectedFile(file?.[index])
                setSelectedId(file?.[index])
                setIsAlertOpen(true)
              }}
            >
              <Iconify icon="material-symbols:close" sx={{ height: 32 }} />
            </IconButton>}
          {/* {!disabled ? (

          ) : (
            ''
          )} */}
        </Stack>
      ))}

      {progress > 0 ? <LinearProgress variant="determinate" value={progress} /> : ''}
      {error ? (
        <Typography
          textTransform="uppercase"
          variant="caption"
          sx={{ color: 'error.main', pl: 2, pt: 1 }}
        >
          {error}
        </Typography>
      ) : (
        ''
      )}
      <RejectionFiles fileRejections={fileRejections} />
    </Box>
  );
}

UploadManualFiles.propTypes = {
  disabled: PropTypes.object,
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.object,
  sx: PropTypes.object,
};
