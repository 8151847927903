import SimpleBar from 'simplebar-react';
// @mui
import { alpha, styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const StyledRootScrollbar = styled('div')(() => ({
  flexGrow:1,
  height: '100%',
}));

export const StyledScrollbar = styled(SimpleBar)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: 'red', // Scrollbar thumb color
      width: '100%',
      borderRadius: '6px', // Rounded scrollbar thumb
    },
    '&:after': {
      backgroundColor: 'green', // Scrollbar thumb color
      width: '100%',
      borderRadius: '0px', // Rounded scrollbar thumb
    },
    opacity: 1, // Ensure scrollbar is always visible
    transition: 'none', // Remove any hiding effect
  },

  '& .simplebar-track': {
    backgroundColor: alpha(theme.palette.grey[800], 0.1),// Track background
    opacity: 1, // Always show the track
  },

  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: '24px', // Horizontal scrollbar height
    bottom: '0', // Align with the bottom edge
  },

  '& .simplebar-track.simplebar-vertical .simplebar-scrollbar': {
    width: '24px', // Vertical scrollbar width
    right: '0', // Align with the right edge
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));
