import React, { Component, useState } from 'react';
import { Button, DialogContent, ListItem, ListItemText, Divider, Typography, Box, Chip, Grid } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { getSignedURltoviewFile } from 'src/services/file';
import { fCurrency } from 'src/utils/format-number';
import { HasAccess } from '@permify/react-role';
import { display } from '@mui/system';
import PdfPreviewPopup from 'src/components/common/pdfPreview';
import Iconify from 'src/components/iconify/iconify';


export default function CommercialInvoiceAndBinder({ row, podFilteredForRow, sx }) {
  const [open, setOpen] = useState(false);
  const openlink = async (i) => {
    let file = await getSignedURltoviewFile(i);
    window.open(file);
  };
  
  return (
    <>
      <Dialog keepMounted onClose={() => setOpen(false)} open={open} fullWidth={true} maxWidth={'xl'}>
      <DialogTitle>Delivery Proof Files</DialogTitle>
      <DialogTitle>Project Id: {row?.projectId  }</DialogTitle>
      {podFilteredForRow && podFilteredForRow[0] ? 
        <DialogContent sx={{ minHeight: 300 }}>
        <Box sx={{display:'flex',justifyContent:'space-around', mb:4 }}>
        <Typography
                    style={{ textTransform: 'uppercase', width:'30%' }}
                    size={'small'}
                    variant="contained"
                    sx={{color:'#000', fontWeight:600}}
                  >
                     Tracking ID
                  </Typography>
                  <Typography
                    style={{ textTransform: 'uppercase' , width:'20%'}}
                    size={'small'}
                    variant="contained"
                    sx={{color:'#000', fontWeight:600}}
                  >
                     Delivery Challan
                  </Typography>
                  <Typography
                    sx={{color:'#000', fontWeight:600}}
                    style={{ textTransform: 'uppercase', width:'20%' }}
                    size={'small'}
                    variant="contained"
                  >
                    Signed Challan
                  </Typography>
                  </Box>
          {podFilteredForRow && podFilteredForRow[0]  && podFilteredForRow[0]?.trackingIds_Signed_deliveryChallans?.map((i, index) => (
            <>
            <Box sx={{display:'flex',justifyContent:'space-between' , width:'100%', my:2}}>
              <Box sx={{width:'30%'}}>
            {i?.trackingId ? 
                  <Typography
                    style={{ textTransform: 'uppercase' }}
                    size={'small'}
                    variant="contained"
                  >
                   {index+1}. {"  "}{i.trackingId}
                  </Typography>:<Typography>No File</Typography>}
                  </Box>
              <Box sx={{width:'20%',  alignItems:"center"}}>

                {i?.deliveryChallanGeneratedCopy ? 
                 <PdfPreviewPopup pdfUrl={i?.deliveryChallanGeneratedCopy} label="View Delivery Challan" />
                 :<Typography>No File</Typography>}
                </Box>
              <Box sx={{width:'20%',  alignItems:"center"}}>
                  {i?.binderFilePath ? 
                //   <Button
                //     style={{ textTransform: 'uppercase' }}
                //     size={'small'}
                //     variant="contained"
                //     onClick={() => openlink(i.binderFilePath)}
                //   >
                //     Download Signed Challan
                //   </Button>
                 <PdfPreviewPopup pdfUrl={i?.deliveryChallanSignedCopy} label="View Signed Challan" />

                  :<Typography>No File</Typography>}
                  </Box>
                  </Box>
              <Divider />
            </>
          ))}
        </DialogContent>
         :<DialogContent sx={{ minHeight: 300, display:'flex', justifyContent:'center', alignItems:'center' }}> <Typography> No File Generated</Typography></DialogContent>}
      </Dialog>
      <Button
      sx={{
        color:"black",
              backgroundColor: '#D9D9D9',
              px: 5,
              borderRadius: 5,
              mt: 2,
              mr:2,
              ...sx
            }}
        style={{ textTransform: 'uppercase' }}
        variant={'contained'}
        onClick={() => setOpen(true)}
      >
         Proof of delivery
      </Button>
    </>
  );
}
