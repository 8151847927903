import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Container, Typography, Card } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { primaryPresets } from 'src/theme/options/presets';
import { useOutletContext } from 'react-router';
import EshopPurchaseTable from '../eshopPurchaseTable';
import { useParams } from 'src/routes/hooks';
import { ArrowDropDown } from '@mui/icons-material';
import { getAllVendorApprovedRequests } from 'src/services/vendorDashboard';


export default function EshopPurchasePlan() {
    const { id, partId, vid } = useParams();
  const settings = useSettingsContext();
  const [data, loading] = useOutletContext();
  const [requestData, setRequestData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [currency, setCurrency] = useState({});

  useEffect(() => {
    getAllVendorApprovedRequests(id, partId, vid).then((vdata) => {
      setRequestData(vdata);
      setCurrency(vdata?.[0]?.quote?.currency);
    });
  }, [refresh]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Box display="flex">
        <Box>
          <CustomBreadcrumbs
            heading="ESHOP PURCHASE PLAN"
            links={[
              { name: 'Home', href: '/home' },
              {
                name: 'Vendor',
                href: `/quote/${id}/vendor`,
              },
              {
                name: 'Vendor Selection',
                href: `/quote/${id}/vendor/vendor-selection`,
              },
              {
                name: 'ESHOP',
                href: `/quote/${id}/eshop`,
              },
              {
                name: 'ESHOP PURCHASE PLAN',
                href: '/eshop',
              },
            ]}
            sx={{ mb: { xs: 3, md: 5 } }}
          />
          <Box display={'flex'} >
              {/* <Button
                href={`/quote/${id}/eshop`}
                variant="contained"
                sx={{ borderRadius: 0,width: 180,color:"black", ml: 1, fontSize: 14, backgroundColor: 'gray',mt:1}}
              >
                ESHOP
              </Button> */}
              <Button
                variant="contained"
                sx={{
                  borderRadius: 0,
                  color:"black",
                  ml: 1,
                  width: 240,
                  fontSize: 14,
                  backgroundColor: 'grey',
                  borderTop: '5px solid red'
                }}
              >
                ESHOP PURCHASE PLAN
              </Button>
              <Button
                href={`/quote/${id}/eshop/eshop-orders`}
                variant="contained"
                sx={{ borderRadius: 0,width: 240,color:"black", ml: 1, fontSize: 14, backgroundColor: 'gray',mt:1}}
              >
                ORDERS
              </Button>
              <Button
                href={`/quote/${id}/eshop/eshop-archive`}
                variant="contained"
                sx={{ borderRadius: 0,width: 240,color:"black", ml: 1, fontSize: 14, backgroundColor: 'gray', mt: 1 }}
              >
                ARCHIVE
              </Button>
          </Box>
        </Box>
        <Box borderLeft={2} marginLeft={2} paddingLeft={2} borderColor={"white"} color={'white'}>
        <Card sx={{p:2}}>
        <Typography textTransform="uppercase" variant="caption">{`Project ID: ${data?.projectId}`}</Typography><br/>
        <Typography textTransform="uppercase" variant="caption">{`RFQ: ${data?.RFQ}`}</Typography><br/>
        <Typography textTransform="uppercase" variant="caption">{`RFQ NAME: ${data?.RFQName}`}</Typography><br/>
        <Typography textTransform="uppercase" variant="caption">{'Recently edited by - {User Name}'}</Typography>
        </Card>
        </Box>
        <Box borderLeft={2} marginLeft={2} paddingLeft={2} borderColor={"white"}>
          <Button
            href={`/quote/l1/${id}`}
            style={{background: 'white', color: 'black', borderRadius:"20px" }}
            variant="outlined"
          >
            <Typography textTransform="uppercase" variant="button" b>
              GO TO Quote LEVEL 1
            </Typography>
            <ArrowDropDown fontSize="small" sx={{ color: 'black' }} />
          </Button><br/>
          <Button
            href={`/quote/l2/${id}`}
            style={{background: 'white', color: 'black', borderRadius:"20px", marginTop:"10px" }}
            variant="outlined"
          >
            <Typography textTransform="uppercase" variant="button" b>
              GO TO Quote LEVEL 2
            </Typography>
            <ArrowDropDown fontSize="small" sx={{ color: 'black' }} />
          </Button>
        </Box>
      </Box>
      <EshopPurchaseTable
        loading={loading}
        requestData={requestData}
        currency={currency}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </Container>
  );
}
