import axios, { endpoints } from 'src/utils/axios';

export const createDeliveryTracking = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.deliveryTracking.create}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getTracking = (search) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `${endpoints.deliveryTracking.all}?search_tracking=${search}`
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

  export const getTrackingByProjectId = (projectId) =>
    new Promise((resolve, reject) => {
      axios
        .get(
          `${endpoints.deliveryTracking.all}?search_project=${projectId}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

export const GET_ALL_DELIVERY = (page, rowsPerPage) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.deliveryTracking.getALLDelivery}?page=${page}&limit=${rowsPerPage}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const GET_ALL_DELIVERY_ALL_DATA = (page, rowsPerPage) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.deliveryTracking.getALLDeliveryAllData}?page=${page}&limit=${rowsPerPage}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getDeliveryDetails = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.deliveryTracking.getDetails}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const acceptDelivery = (id) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.deliveryTracking.accept}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const declineDelivery = (id) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.deliveryTracking.decline}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const updateStatus = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.deliveryTracking.updateStatus}${id}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

  export const rollbackTrackingStatus = (id) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.deliveryTracking.trackingRollbackStatus}${id}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

export const updateTracking = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.deliveryTracking.update}${id}`, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
export const updateDeliveryChallan = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.deliveryTracking.updateDeliveryChallan}${id}`, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });

export const updateInitiateDelivery = (id) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.deliveryTracking.initiateDelivery}${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });

///////ETA RELATED
export const getAllETAList = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.deliveryTracking.getETADetails}${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });

export const createNewETA = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.deliveryTracking.createNewETA}${id}`, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });

export const updateExistingETA = (id, etaId, data) =>
  new Promise((resolve, reject) => {
    axios
      .put(`${endpoints.deliveryTracking.updateExistingETA}${id}/${etaId}`, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });

////////////////////////////////

export const addDeliveryDetailsVendor = (id, body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.deliveryTracking.addDeliveryDetailsVendor}${id}/delivery`, body)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const getTrackingsGroupedbyPart = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.deliveryTracking.getTrackingsbyPart}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getALLDeliveryGroupedByTracking = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.deliveryTracking.getTrackingsbyTrackId}/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const updateVendorReqDelivery = (vendorRequestId, deliveryItemId, data) =>
  new Promise((resolve, reject) => {
    axios
      .put(
        `${endpoints.deliveryTracking.updateVendorReqDelivery}${vendorRequestId}/delivery/${deliveryItemId}`,
        data
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getTrackingStatus = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.deliveryTracking.getTrackingStatus}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const generateChallan = (id) =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${endpoints.deliveryTracking.generateChallan}${id}`,
        {},
        {
          responseType: 'arraybuffer',
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'challan.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        resolve(response);
      })
      .catch((error) => reject(error));
  });


  export const getAllDeliveryByProjectAndLineitmeIdService = (projectId, lineitemId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${endpoints.deliveryTracking.getAllDeliveryByProjectAndLineitmeId}${projectId}/${lineitemId}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

    export const generateTrackingNumberService = () =>
      new Promise((resolve, reject) => {
        axios
          .get(`${endpoints.deliveryTracking.generateTrackingNumber}`)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
