import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/users/me',
    login: '/api/users/login',
    register: '/api/auth/register',
    vendorRegister: '/api/vendor/create',
    vendorLogin: '/api/vendor/login',
  },
  comment: {
    getCommentsList: '/api/comment',
    addComment: '/api/comment/add',
  },
  client: {
    get: '/api/client/search',
  },
  clientInfo: {
    list: '/api/client/all/',
    add: '/api/client/new',
    update: '/api/client/update/',
    delete: '/api/client/delete',
    getLogo: '/api/client/search?',
    addUser: '/api/users/register',
    getUserList:'/api/users/client/',
    updateUser: 'api/users/'
  },
  organization: {
    getById: '/api/organization/get-org-by-id/',
    get: '/api/organization/get-organizations',
    update: '/api/organization/update-organization/',
    add: '/api/organization/add-organization',
    getAll: '/api/organization/all-organizations',
    delete: '/api/organization/delete-organization/',
    address: {
      getHubAddress: '/api/organization/get-hubaddresses',
      addHubAddress: '/api/organization/add-hubaddress',
      updateHubAddress: '/api/organization/update-hubaddress/',
      deleteHubAddress: '/api/organization/delete-hubaddress/',
    },
  },
  quote: {
    getAll: '/api/quotes/getallquotes',
    create: '/api/quotes/create',
    update: '/api/quotes/update',
    uploadManual: '/api/quotes/uploadQuoteFile',
    deleteManualFile: '/api/quotes/deleteQuoteFile',
    get: '/api/quotes/getQuotedetails/',
    getPN: 'api/quotes/pn/',
    additems: '/api/quotes/updatelineitems',
    updateLineItemDetails: '/api/quotes/updatelineitem',
    addNewLineItem: '/api/quotes/addlineitem',
    l2Complete: '/api/quotes/setl2complete/',
    sendQuoteForApproval: '/api/quotes/admin-approval',
    markForApproval: '/api/quotes/markForApproval/',
    approveItem: '/api/quotes/setItemApproved/',
    addBenifitAndShipping: '/api/quotes/add-benifit-shipping',
    approveQuote: '/api/quotes/approve-quote/',
    archiveQuote: '/api/quotes/archive/',
    restoreQuote: '/api/quotes/restore/',
    reviewQuote: '/api/quotes/addreview',
    addPo: '/api/quotes/addpo/',
    updateHSN: '/api/quotes/update-hsn/',
    setGRApproval: '/api/quotes/setGRApproval/',
    setInvoiceBooked: '/api/quotes/setInvoiceBooked/',
    setPaymentStatus: '/api/quotes/setPaymentStatus/',
    search: '/api/quotes/search?text=',

  },
  pdfGenerate: {
    generateQuotePdf: '/api/pdfGenerator/quotation/',
    generateProformaPdf: '/api/pdfGenerator/profarmaInvoice/',
    getpdfDetails: '/api/pdfDetails/pdf-detail/',
    generateCommercialPdf: '/api/pdfGenerator/commercialInvoice/',
    generateBinderPdf: '/api/pdfGenerator/binderFile',
    generateClientLedgerPdf: '/api/client/ledger/'
  },
  eshop: {
    getQuotedProjectDetails: 'api/eshop/getQuotedRequest-of-perticular-part/',
    quoteRequest: 'api/eshop/quote-request',
    getEshopRequestSuggestionsUrl: 'api/vendor-request/latest-eshop-vendor-requests/',
  },
  vendor: {
    dashboard: {
      statusCheck: '/api/vendor/status-check',
      pendingProjects: '/api/vendor/pending-projects',
      quotedProjects: '/api/vendor/accepted-projects',
      inProcessProjects: '/api/vendor/in-process-projects',
      getVendorById: '/api/vendor/get/',
      updateVendorById: '/api/vendor/update/',
      deleteVendorById: '/api/vendor/delete/',
      acceptRequest: '/api/vendor-request/accept/',
      allRequestofProjects: '/api/vendor/accepted-request-of-perticular-project/',
      getAllVendorApprovedRequests: '/api/vendor-request/getallvendorApprovedquotes/',
      getAllVendorApprovedRequestsEshop: '/api/vendor-request/getallvendorApprovedquotes-eshop/',
      quoteRequest: 'api/vendor-request/quote-request',
    },
    approveQuote: 'api/vendor-request/approve-quoted-request/',
    rejectQuote: 'api/vendor-request/reject-quoted-request/',
    getQuotedProjectDetails: 'api/vendor-request/getQuotedRequest-of-perticular-part/',
    getQuoteDetails: '/api/quotes/getQuotedetails/',
    getCountries: '/api/common/allcountries',
    getRegions: '/api/common/regions',
    getCountriesForVendor: '/api/vendor/distinct/countries',
    getRegionsForVendor: '/api/vendor/distinct/region',
    getSpecializationForVendor: '/api/vendor/distinct/secializations',
    getVendorList: '/api/vendor/list',
    createVendorRequest: '/api/vendor-request/create-vendor-requests',
    initiateChangeApproval: 'api/vendor-request/initiate_change_approval/',
    approvalChangeApproval: 'api/vendor-request/approve_change/',
    archiveVendorRequest: 'api/vendor-request/archive-vendor-request/',
    restoreArchivedVendRequest: 'api/vendor-request/restore-vendor-request/',
    getArchivedVendorRequestByFilter: 'api/vendor-request/get-archived-vendor-requests/filter?projectId='
  },
  file: {
    getuploadUrl: '/api/common/generateSignedUrl',
    getfileUrl: '/api/common/retrieveFile',
  },
  deliveryTracking: {
    create: '/api/deliveryTracking/create',
    all: '/api/deliveryTracking/getalltrackingids',
    getDetails: '/api/deliveryTracking/getdetails/',
    accept: '/api/deliveryTracking/accept/',
    decline: '/api/deliveryTracking/decline/',
    updateStatus: '/api/deliveryTracking/updateStatus/',
    update: '/api/deliveryTracking/updateTracking/',
    updateDeliveryChallan: '/api/deliveryTracking/updateChallanFields/',
    initiateDelivery: '/api/vendor-accounting/initiateDelivery/',
    getALLDelivery: '/api/delivery/get-all-deliveries-grouped-by-project',
    getALLDeliveryAllData: '/api/delivery/get-all-deliveries-grouped-by-project_alldata',
    getAllDeliveryByProjectAndLineitmeId:'/api/delivery/get-all-deliveries-grouped-by-trackingId-lineId/',
    addDeliveryDetailsVendor: '/api/delivery/vendor-requests/',
    getTrackingsbyPart: 'api/delivery/get-all-deliveries-grouped-by-part/',
    getTrackingsbyTrackId: 'api/delivery/get-all-deliveries-grouped-by-trackingId',
    updateVendorReqDelivery: `api/delivery/vendor-requests/`,
    getTrackingStatus: '/api/deliveryTracking/getTrackingStatus/',
    generateChallan: '/api/pdfGenerator/deliveryChallan/',
    getETADetails: '/api/deliveryTracking/eta/',
    createNewETA: '/api/deliveryTracking/eta/',
    updateExistingETA: '/api/deliveryTracking/eta/',
    generateTrackingNumber: '/api/deliveryTracking/generate-tracking-number',
    trackingRollbackStatus:'/api/deliverytracking/rollbackStatus/'
  },
  common: {
    getAllCurrency: '/api/currency/get-currencies',
    lineItemMoveDown: '/api/quotes/movedown/',
    lineItemMoveUp: '/api/quotes/moveup/',
    convertCurrency: '/api/currency/convertCurrency',
    updateCurrency: '/api/currency/update-currency',
    addNewCurrency: '/api/currency/insert-currency',
    deleteCurrency: '/api/currency/delete/',
    updateWarranty: '/api/quotes/update-warranty/',
    cancelItem: '/api/common/cancel',
    reject: '/api/common/reject/',
    rollback: '/api/common/rollback/'
  },
  accounting: {
    getAccountingDetails: '/api/quotes/getAccountingDetails/',
    addAccountingAdvance: '/api/quotes/addAccounting_Advance/',
    adminPaymentApprove: '/api/quotes/quoteadminpaymentapprove/',
    initiatePaymentApproval: '/api/quotes/initiatepaymentapproval/',
    calculateAdvancePercentagePrice: '/api/quotes/contract_percentage_price/',
    requestAdvanceAdminApproval: '/api/quotes/requestAdvanceAdminApproval/',
    approveLatestProformaAdvance: '/api/quotes/setAdvanceAdminApproval/',
    recivedLatestProformaAdvance: 'api/quotes/setIsPaymentReceived/',
    getAllDeliveriesByProject: '/api/delivery/get-all-deliveries-by-project/',
    mapCommercialInvoice: '/api/quotes/map/'
  },
  purchaseOrder: {
    getQuotedetails: '/api/quotes/getQuotedetails/',
    getAllAccounting: '/api/quotes/getallAccounting',
    getPOAllList: '/api/quotes/getallPO',
    updateSignedFile: '/api/quotes/addsignedPo/',
    purchaseOrderInvoice: '/api/pdfGenerator/purchaseOrderInvoice/',
    updateCOC: '/api/quotes/updatecoc/',
  },
  accountingVendor: {
    getAllVendorApprovedRequests: '/api/vendor-accounting/getallvendorApprovedquotes/',
    updateAccountingdetails: '/api/vendor-accounting/updateaccountingdetails/',
    generatePO: '/api/pdfGenerator/purchaseOrderInvoice/',
    generateLedger: '/api/pdfGenerator/generate-ledger-pdf/',
    adminApproval: '/api/vendor-accounting/adminApproval/',
  },
  notification: {
    getAllNotification: '/api/notification/all/',
    addNotification: '/api/notification/add/',
    pickedIndividualNotification: '/api/notification/update/',
  },
  unit: {
    getAllUnit: '/api/unit/get-units/',
    addNewUnit: '/api/unit/add/',
    updateUnitDetails: '/api/unit/update/',
    deleteUnitDetails: '/api/unit/delete/',
  },
};
