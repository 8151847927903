import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Snackbar,
  OutlinedInput,
  ListItemText,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { RHFMultiSelect } from 'src/components/hook-form';
import Scrollbar from 'src/components/scrollbar';
import { useTable } from 'src/components/table';
import {
  createVendorRequest,
  getCountriesForVendor,
  getRegionsForVendor,
  getSpecializationForVendor,
  getVendorList,
} from 'src/services/vendor';

function createData(id, slNo, desc, pn, deliveryLeadTime, vend) {
  return {
    id,
    slNo,
    desc,
    pn,
    deliveryLeadTime,
    vend,
  };
}

export default function VendorTable(props) {
  const { loading, data, projectId } = props || {};
  const table = useTable({ defaultRowsPerPage: 10 });
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [specialised, setSpecialised] = useState('');
  const [vendorSelection, setVendorSelection] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [selectedRows, setSelectedRows] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');
  const [requestLoading, setRequestLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (data?.length) {
      const tableArray = data?.map((e, ind) => {
        let vend = e?.vendors?.map((item) => item?.vendorId?.name).join(',');
        return createData(
          e?._id,
          ind + 1,
          e?.description,
          e?.PN,
          e?.deliveryLeadTime ? `${e?.deliveryLeadTime} ${e?.deliveryLeadTimeUnit}` : '---',
          vend ? vend : '--'
        );
      });
      setTableData(tableArray);
    }
  }, [data, data?.length]);

  useEffect(() => {
    getCountriesForVendor().then((response) => {
      setCountries(response);
    });
  }, []);

  useEffect(() => {
    if (country) {
      getRegionsForVendor(country).then((response) => {
        setRegions(response);
      });
    }
  }, [country]);

  useEffect(() => {
    if (country && region) {
      getSpecializationForVendor(country, region).then((response) => {
        setSpecializations(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region]);

  useEffect(() => {
    // if (country && region && specialised) {
    if (country) {
      if (country === 'ALL') {
        getVendorList().then((response) => {
          setVendors(response);
        });
      } else {
        getVendorList(country).then((response) => {
          setVendors(response);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const onChangeCountry = (e) => {
    setCountry(e.target.value);
    // setRegions([]);
    // setSpecializations([]);
    setVendors([]);
  };

  const onChangeRegion = (e) => {
    setRegion(e.target.value);
    setSpecializations([]);
    setVendors([]);
  };

  const onChangeSpecialised = (e) => {
    setSpecialised(e.target.value);
    setVendors([]);
  };

  // const onChangeVendorSelection = (e) => {
  //   setVendorSelection(e.target.value);
  // };
  const onChangeVendorSelection = (event) => {
    const {
      target: { value },
    } = event;
    setVendorSelection(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const COLUMNS = [
    { id: 'slNo', label: 'SNO.', minWidth: 100 },
    {
      id: 'desc',
      label: 'Description',
      minWidth: 170,
      render: (value, row) => (value ? <Typography sx={{ width: 320 }}>{value}</Typography> : '--'),
    },
    {
      id: 'pn',
      label: 'P/N',
      minWidth: 170,
    },
    // {
    //   id: 'deliveryLeadTime',
    //   label: 'Delivery Timeline',
    //   minWidth: 170,
    // },
    {
      id: 'vend',
      label: 'Selected Vendors',
      minWidth: 170,
    },
  ];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tableData.map((n) => n?.id);
      setSelected(newSelected);
      setSelectedRows(newSelected.length);
      return;
    }
    setSelected([]);
    setSelectedRows(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (_event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setSelectedRows(newSelected.length);
  };

  const onCreateVendorRequest = async () => {
    const payload = {
      vendorIds: vendorSelection,
      projectId: `${projectId}`,
      partIds: selected,
    };

    setRequestLoading(true);
    const res = await createVendorRequest(payload);
    if (res?.success) {
      setOpen(true);
      setCountry('');
      setRegion('');
      setSpecialised('');
      setVendorSelection([]);
      setSelected([]);
      setSelectedRows(0);
      setErrorMsg('');
    } else {
      setErrorMsg(res?.message);
    }
    setRequestLoading(false);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Box>
      {!!errorMsg && (
        <Alert sx={{ mb: 3 }} severity="error">
          {errorMsg}
        </Alert>
      )}
      {open && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Vender Request Created
          </Alert>
        </Snackbar>
      )}

      <Box
        sx={{ background: 'white', pt: 2, pb: 2, pl: 2 }}
        flexDirection="row"
        display="flex"
        alignSelf="flex-end"
      >
        <FormControl sx={{ width: 200, mr: 1 }}>
          <InputLabel sx={{color:'black'}}>Country</InputLabel>
          <Select label="Country" value={country} onChange={onChangeCountry} style={{borderRadius: '50px',color:"black", background: 'white',border:'1px solid'}}>
            <MenuItem value="ALL">ALL</MenuItem>
            {countries?.map((countryVal) => (
              <MenuItem value={countryVal}>{countryVal}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <FormControl disabled={!specialised} sx={{ width: 200 }}> */}
        {/* <FormControl disabled={!country} sx={{ width: 200, mr:1 }}> */}
        {/* <InputLabel>Vendor Selection</InputLabel>
          <Select
            label="Vendor Selection"
            value={vendorSelection}
            onChange={onChangeVendorSelection}
          >
            {vendors?.map((vendor) => (
              <MenuItem value={vendor?._id}>{vendor?.name}</MenuItem>
            ))}
          </Select> */}
        {/* {vendors[0]&&
          <RHFMultiSelect
                checkbox
                name="colors"
                label="Colors"
                options={vendors}
              />} */}
        {/* {vendors?.map((vendor) => (
              <MenuItem value={vendor?._id}>{vendor?.name}</MenuItem>
            ))}
              </RHFMultiSelect> */}
        {/* </FormControl> */}
        <FormControl sx={{ mr: 1, width: 300 }}>
          <InputLabel sx={{color:'black'}} id="demo-multiple-checkbox-label">Vendor Selection</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={vendorSelection}
            onChange={onChangeVendorSelection}
            input={<OutlinedInput label="Tag" />}
            MenuProps={MenuProps}
            style={{borderRadius: '50px',color:"black", background: 'white',border:'1px solid'}}
          >
            {vendors?.map((item) => (
              <MenuItem key={item?._id} value={item?._id}>
                {item?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <FormControl sx={{ width: 200, mr: 1 }} disabled={!country}> */}
        <FormControl sx={{ width: 200, mr: 1 }} disabled>
          <InputLabel sx={{color:'black'}}>Region</InputLabel>
          <Select label="Region" value={region} onChange={onChangeRegion} style={{borderRadius: '50px',color:"black", background: 'white'}}>
            {regions?.map((regionVal) => (
              <MenuItem value={regionVal}>{regionVal}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <FormControl sx={{ width: 200, mr: 1 }} disabled={!region}> */}
        <FormControl sx={{ width: 200, mr: 1 }} disabled>
          <InputLabel sx={{color:'black'}}>Specialised</InputLabel>
          <Select label="Specialised" value={specialised} onChange={onChangeSpecialised} style={{borderRadius: '50px',color:"black", background: 'white'}}>
            {specializations?.map((specialization) => (
              <MenuItem value={specialization}>{specialization}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Paper elevation={1}>
        <TableContainer sx={{ overflow: 'unset' }}>
          <Scrollbar sx={{ maxHeight: 400 }}>
            <Table stickyHeader sx={{ minWidth: 800 }}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={selected.length > 0 && selected.length < tableData.length}
                      checked={tableData.length > 0 && selected.length === tableData.length}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  {COLUMNS.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, textTransform: 'uppercase', border: 1 }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                        width="100%"
                        height={220}
                      >
                        <CircularProgress />
                        <br />
                        <Typography textTransform="uppercase"> Fetching...</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {tableData
                    .map((row, index) => {
                      const isItemSelected = isSelected(row?.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          onClick={(event) => handleClick(event, row?.id)}
                          aria-checked={isItemSelected}
                          selected={isItemSelected}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          {COLUMNS.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ textTransform: 'uppercase' }}
                              >
                                {column.render ? column.render(value, row) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
            </Table>
          </Scrollbar>
        </TableContainer>
      </Paper>
      <Box
        sx={{
          justifyContent: 'flex-end',
        }}
        alignSelf="flex-end"
        display="flex"
      >
        <Button
          style={{ textTransform: 'uppercase',background:"#bd0909", color:"white" }}
          onClick={onCreateVendorRequest}
          sx={{ width: 100, mt: 2 }}
          variant="contained"
          disabled={!selectedRows || !vendorSelection || requestLoading}
        >
          <Typography textTransform="uppercase" variant="button">
            Submit
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
