import axios, { endpoints } from 'src/utils/axios';

export const login = (email, password) =>
  new Promise((resolve, reject) => {
    axios
      .post(endpoints.auth.login, { email, password })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });



