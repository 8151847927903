import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  Grid,
  Chip,
  Container,
  Typography,
  alpha,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Divider,
  Stack,
} from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { primaryPresets } from 'src/theme/options/presets';
import AccClientLevel2Table from '../accClientLevel2Table';
import { Radio, RadioGroup, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import {
  AdminPaymentApprove,
  AdminInitiatePaymentApprove,
  ApproveLatestProformaAdvanceByAdmin,
  CalculateAdvancePercentagePrice,
  GetAllCommercialInvoiceMappingByProjectId,
  RecivedLatestProformaAdvance,
  RequestAdvanceAdminApproval,
  getAccountingDetails,
} from 'src/services/accounting';
import { useParams, useRouter } from 'src/routes/hooks';
import _ from 'lodash';
import { fCurrency } from 'src/utils/format-number';
import { calculateContractAmount, calculateDiscountAmount, calculateFinalAmount } from 'src/utils/quoteHelperFunc';
import Ledger from 'src/components/accounting/Ledger';
import Iconify from 'src/components/iconify/iconify';
import ContractAmountDisplay from 'src/components/common/ContractAmountDisplay';
import CommonNavigation from 'src/components/custom/CommonNavigation';
import LoadingAsync from 'src/components/common/LoadingAsync';
import { HasAccess, usePermify } from '@permify/react-role';
import { useSnackbar } from 'notistack';
import { Check } from '@mui/icons-material';
import { getQuote, setl2complete, deleteUploadedManualFile, updateQuotes, uploadManualFile  } from 'src/services/quotes';
import { addNewNotification } from 'src/services/notification';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { generateBinderPdf } from 'src/services/pdfGeneration';
import VIewFile from 'src/components/upload/ViewFIle';
import PdfPreviewPopup from 'src/components/common/pdfPreview';
import UploadManualFiles from 'src/components/upload/UploadManualFiles';
import DeleteManualAlert from 'src/sections/Quote/DeleteManualAlert';
import ProofOfDeliveryModal from 'src/components/accounting/ProofOfDeliveryModal';
import { GET_ALL_CLIENT_INFO } from 'src/services/clientInfo';
import { GET_ALL_DELIVERY_ALL_DATA } from 'src/services/delivery';

export default function AccClientLevel2View() {
  const settings = useSettingsContext();
  const { id } = useParams();
  const { isAuthorized, isLoading } = usePermify();
  const [backendAuth, setBackendAuth] = useState(false);
  const [response, setResponse] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [comInvId, setComInvId] = useState();
  const [commInvData, setCommInvData] = useState();
  const router = useRouter();
  const [refresh, setRefresh] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [deleteManualBody, setDeleteManualBody] = useState({});
  const [seletedFilesName, setSelectedFilesName] = useState();
  const [proofOfDelData, setProofOfDelData] = useState({});
  
  useEffect(() => {
    if (id) {
      getAccountingDetails(id)
        .then((response) => {
          setResponse(response);
        })
        .catch((error) => {});
    }
   GetAllCommercialInvoiceMappingByProjectId(id).then((res) => setCommInvData(res));
   const callAuth = async() => {
    let tempAuth = await isAuthorized([],"backend");
    await setBackendAuth(tempAuth)
  }
callAuth();
  }, [id, refresh]);

  const [advanceAmountPerError, setAdvanceAmountPerError] = useState(false);
  const [advanceAmountPer, setAdvanceAmountPer] = useState('');
  const [advanceAmount, setAdvanceAmount] = useState('');

  const handleChange = (event) => {
    if (event?.target?.id === 'percent') {
      setAdvanceAmountPer(event?.target?.value);
      if (event?.target?.value >= 0 && event?.target?.value <= 100) {
        setAdvanceAmountPerError(false);
      } else {
        setAdvanceAmountPerError(true);
      }
    } else {
      setAdvanceAmount(event?.target?.value);
    }
  };

  const [showChip, setShowChip] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 350) { 
        setShowChip(true);
      } else {
        setShowChip(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getAmountValue = (event) => {
    if (id && event?.target?.value) {
      CalculateAdvancePercentagePrice(id, event.target.value)
        .then((response) => {
          setAdvanceAmount(response?.percentageAmount);
        })
        .catch((error) => {
          console.info(error);
        });
    }
  };

  const onCompleteL2 = () => {
    setLoading(true);
    setl2complete(id)
      .then(() => {
        setLoading(false);
        enqueueSnackbar('Marked l2 task completed');
        //router.push('/home/accounting');
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Error! Please try again');
        //router.push('/home/accounting');
      });
  };

  const binderFileGenerator = () => {
    if(comInvId){
    setLoading(true);
    generateBinderPdf(id,comInvId)
      .then(() => {
        setLoading(false);
        enqueueSnackbar('PDF Generated');
        //router.push('/home/accounting');
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar(err?.data?.error? err?.data?.error: 'Error! Please try again');
        //router.push('/home/accounting');
      });
    }else {
      alert('Please Choose Commercial Invoice')
    }
  };
  const addNotification = () => {
    const newNotification = {
      projectId: id,
      pageName: 'ACCOUNTING CLIENT - LEVEL 1',
      title: `ACCOUNTING CLIENT - LEVEL 1 PENDING FOR APPROVAL : ${response?.quote?.projectId}`,
      desc: 'NEW REQUEST COMING FROM ACCOUNTING CLIENT - LEVEL 1 FOR APPROVAL',
      redirectURL: `/accounting-client-level2/${id}`,
    };

    addNewNotification(newNotification)
      .then(() => {
        // enqueueSnackbar('Notification Added');
      })
      .catch((err) => {
        // enqueueSnackbar('Error! Please try again');
      });
  };

  const handleRequestAdminApprove = () => {
    if (advanceAmountPer && advanceAmount) {
      const data = {
        percentage: advanceAmountPer,
        amount: advanceAmount,
        setApproval: true,
      };
      RequestAdvanceAdminApproval(id, data)
        .then((response) => {
          // console.log(response)
          enqueueSnackbar('Request Sent');
          setRefresh(refresh + 1);
        })
        .catch((error) => {});
    } else {
      enqueueSnackbar('Please Enter Advance Percentage');
    }
  };

  const handleAdminApproveLatestProforma = () => {
    if (!response?.quote?.advanceAdminApproval) {
      ApproveLatestProformaAdvanceByAdmin(id, { setApproval: true })
        .then((response) => {
          enqueueSnackbar('Request Approved');
          setRefresh(refresh + 1);
        })
        .catch((error) => {});
    } else {
      enqueueSnackbar('Please Enter Advance Percentage');
    }
  };

  const handleLatestProformaReciveAmount = () => {
    if (!response?.quote?.isadvancePaymentReceived) {
      RecivedLatestProformaAdvance(id, { setPaymentReceived: true })
        .then((response) => {
          enqueueSnackbar('Request Approved');
          setRefresh(refresh + 1);
        })
        .catch((error) => {});
    } else {
      enqueueSnackbar('Please Enter Advance Percentage');
    }
  };


  async function updateManualFiles(fieldName, url) {
    let reqData; 
    switch (fieldName) {
      case 'commercial':
        reqData = { "manualCommercialFile": [url.at(-1)] }
        break;
      case 'binder':
        reqData = { "manualBinderFile": [url.at(-1)] }
        break;

      default:
        break;
    }
    console.log("updateManualFiles", id, reqData)
    await uploadManualFile(
      id,
      reqData
    )
      .then((resp) => {
        setRefresh(refresh + 1);
        enqueueSnackbar("Added Invoice File");
      })
      .catch((err) => {
        // console.log('err', err);
        enqueueSnackbar("Failed to Upload Invoice", { variant: 'error' });
      });


  }

  React.useEffect(() => {
        GET_ALL_DELIVERY_ALL_DATA()
        .then((resp) => {
        let podFilteredForRow = resp?.data?.filter(item => item._id === response?.quote?._id);
        setProofOfDelData(podFilteredForRow);
        })
        .catch((err) => {});
}, [response]);


  async function openDeleteConfirmationModal(fieldName, filesName) {
    setIsAlertOpen(true)
    let reqData; 
    switch (fieldName) {
      case 'commercial':
        reqData = { key:"manualCommercialFile",
                    fileUrl: filesName 
                  }
        setAlertTitle("Do you want to delete below Manual Commercial Invoice ?")
        setDeleteManualBody(reqData)
        setSelectedFilesName(filesName)
        break;    
      case 'binder':
        reqData = { key:"manualBinderFile",
                    fileUrl: filesName 
                  }
        setAlertTitle("Do you want to delete below Manual Binder File ?")
        setDeleteManualBody(reqData)
        setSelectedFilesName(filesName)
        break;    
      default:
        break;
    }
  }

  function handleClose() {
    setIsAlertOpen(false)
  }

  async function confirmDeleteClick() {
    setIsAlertOpen(false)

    await deleteUploadedManualFile(
      id,
      deleteManualBody
    )
      .then((resp) => {
        setRefresh(refresh + 1);
        enqueueSnackbar("Selected Manual Invoice File Deleted");
        setAlertTitle("")
        setDeleteManualBody({})
        setSelectedFilesName()
      })
      .catch((err) => {
        // console.log('err', err);
        enqueueSnackbar("Failed to Delete", { variant: 'error' });
        setAlertTitle("")
        setDeleteManualBody({})
        setSelectedFilesName()
      });
  }
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Please Select Commercial Invoice</DialogTitle>
        <DialogContent sx={{ paddingBottom: 3 }}>
          <Box display="flex" flexDirection="column">
            {commInvData ? 
            <FormControl component="fieldset">
              <FormLabel component="legend">Commercial Invoice List</FormLabel>
              <RadioGroup aria-label="gender" name="gender" value={comInvId} onChange={(e)=>setComInvId(e.target.value)}>
                {commInvData?.commercialInvoices?.map((item)=> {
                 return <FormControlLabel value={item?._id} control={<Radio />} label={item?.name} />
                })}
              </RadioGroup>
              <Button onClick={()=>binderFileGenerator()}>Created Binder File</Button>
            </FormControl>:
            <Typography>No Commercial Invoice Found</Typography>}
          </Box>
        </DialogContent>
      </Dialog>
      <DeleteManualAlert
        alertTitle={alertTitle}
        isAlertOpen={isAlertOpen}
        handleClose={handleClose}
        handleYesClick={confirmDeleteClick}
        fileName={seletedFilesName}
      />
      <Box display="flex">
        <Box sx={{ width: '70%' }}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box
              sx={{ width: '40%', borderRight: '1px solid white', pr: 3 }}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box>
                <CustomBreadcrumbs
                  heading="ACCOUNTING CLIENT"
                  links={[
                    { name: 'Home', href: '/home' },
                    { name: 'ACCOUNTING LIST', href: '/home/accounting' },
                    { name: 'ACCOUNTING CLIENT Level 2', href: '/home' },
                  ]}
                  sx={{ mb: 2, color: 'white' }}
                />
                <Stack>
                  <Stack sx={{ mb:2 }} direction={'row'} justifyContent={'space-between'}>
                  <Chip
                      textTransform="uppercase"
                      label={`PROJECT ID : ${response?.quote?.projectId}`}
                      style={{ 
                        background: 'gray',
                        color:'black',
                        fontWeight:'bold',
                        width: '100%',
                       }}
                    />
                  </Stack>
                  <Stack direction={'row'} justifyContent={'space-between'}>

                  <Typography
                    sx={{ color: 'white' }}
                    variant="span"
                    gutterBottom
                    textTransform="uppercase"
                  >
                    CURRENCY : {response?.quote?.currency?.symbol} {response?.quote?.currency?.abbreviation}
                  </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Typography
                sx={{ color: 'white' }}
                variant="h4"
                gutterBottom
                textTransform="uppercase"
              >
                Level 2
              </Typography>
            </Box>
            <Box
              sx={{ width: '60%', pr: 2, pl: 2 }}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box sx={{ borderRight: '1px solid white', pr: 3, mt: 4, width: '50%' }}>
                <ContractAmountDisplay data={response?.quote} />
                <Stack spacing={1}>
                  <Box display={'flex'} sx={{ color: 'white' }} justifyContent={'space-between'}>
                    <Typography textTransform="uppercase">Amount Received</Typography>
                    <Typography
                      textTransform="uppercase"
                      variant="subtitle1"
                    
                      sx={{ ml: 3 }}
                    >
                      {fCurrency(
                        _.sumBy(response?.quote?.proformaInvoice, (i) => i.amount),
                        response?.quote?.currency?.symbol
                      )}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box display={'flex'} sx={{ color: 'white' }} justifyContent={'space-between'}>
                    <Typography textTransform="uppercase">Balance Amount</Typography>
                    <Typography
                      textTransform="uppercase"
                      variant="subtitle1"
                    
                      sx={{
                        color: 'white',
                        textDecoration: 'underline',
                        textDecorationColor: 'red',
                        ml: 3,
                      }}
                    >
                      {fCurrency((calculateFinalAmount(response?.quote)+(response?.quote?.cgst/100)*(calculateContractAmount(response?.quote)-calculateDiscountAmount(response?.quote)+response?.quote?.airFreight)+(response?.quote?.sgst/100)*(calculateContractAmount(response?.quote)-calculateDiscountAmount(response?.quote)+response?.quote?.airFreight)+(response?.quote?.igst/100)*(calculateContractAmount(response?.quote)-calculateDiscountAmount(response?.quote)+response?.quote?.airFreight)+(response?.quote?.vat/100)*(calculateContractAmount(response?.quote)-calculateDiscountAmount(response?.quote)+response?.quote?.airFreight))-
                        _.sumBy(response?.quote?.proformaInvoice, (i) => i.amount),
                        response?.quote?.currency?.symbol
                      )}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box
                sx={{
                  pr: 3,
                  pl: 3,

                  pt: 4,
                  // borderRight: '1px solid white',
                  color: 'white',
                  width: '50%',
                }}
              >
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '60%' }}>PROJECT ID</Box>:
                  <Box sx={{ width: '40%', pl: 3 }}>{response?.quote?.projectId}</Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '60%' }}>RFQ NO.</Box>:
                  <Box sx={{ width: '40%', pl: 3, wordWrap: 'break-word' }}>{response?.quote?.RFQ}</Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '60%' }}>RFQ NAME</Box>:
                  <Box sx={{ width: '40%', pl: 3, wordWrap: 'break-word' }}>{response?.quote?.RFQName}</Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '60%' }}>PO NUMBER</Box>:
                  <Box sx={{ width: '40%', pl: 3, wordWrap: 'break-word'}}>{response?.quote?.clientPO}</Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '60%' }}>ACCEPTANCE DATE</Box>:
                  <Box sx={{ width: '40%', pl: 3 }}>
                    {moment(response?.quote?.AcceptedPODate).format('DD-MM-YYYY')}
                  </Box>
                  {/* <Box sx={{ width: '40%', pl: 3 }}>{response?.quote?.AcceptedPODate}</Box> */}
                </Box>
              </Box>
              {/* <Box
                sx={{
                  mb: 9,
                  mt: 4,
                  color: 'white',
                  width: '25%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button>
                  <Check sx={{ color: 'red' }} />
                  MARK AS COMPLETE
                </Button>
              </Box> */}
            </Box>
          </Box>
        </Box>
        {backendAuth === false ? 
        <Box
          sx={{
            color: 'white',
            pr: 2,
            pl: 2,
            pt: 5,
            mb: 5,
            width: '30%',
            borderLeft: '1px solid white',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography>Quick Actions</Typography>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="contained"
              sx={{
                color: 'black',
                backgroundColor: '#D9D9D9',
                px: 5,
                borderRadius: 5,
                width: '40%',
                //  boxShadow: '0px 8px 10px #327B96',
                mt: 2,
              }}
            >
              Accounting
            </Button>
            <HasAccess roles={['admin']} permissions={['accounting_frontend_view_ledger']}>
              <Grid item sx={{ width: '40%', mr: 2 }}>
                <Ledger
                  sx={{ width: '100%' }}
                  data={response?.quote}
                  invoices={response?.quote?.proformaInvoice}
                  currency={response?.quote?.currency?.symbol}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              </Grid>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_client_backend_level1_view_commercial_invoice_pdf']}
            >
              <Grid item sx={{ width: '40%' }}>
                <Button
                  sx={{
                    backgroundColor: '#D9D9D9',
                    px: 5,
                    color: 'black',
                    borderRadius: 5,
                    width: '100%',
                    // boxShadow: '0px 8px 10px #327B96',
                    mr: 2,
                    mt: 2,
                  }}
                  style={{ textTransform: 'uppercase' }}
                  variant="contained"
                  startIcon={<Iconify width={20} icon="carbon:download" />}
                  onClick={() => router.push(`/generateCommercialPdf/${id}`)}
                >
                 Commercial Invoice
                </Button>
              </Grid>
            </HasAccess>
            <Button
              sx={{
                backgroundColor: '#D9D9D9',
                px: 5,
                color: 'black',
                borderRadius: 5,
                width: '40%',
                // boxShadow: '0px 8px 10px #327B96',
                mt: 2,
                mr: 2,
              }}
              // onClick={(e) => binderFileGenerator()}
              style={{ textTransform: 'uppercase' }}
              variant="contained"
              onClick={() => setOpenModal(true)}
              // startIcon={<Iconify width={20} icon="carbon:download" />}
            >
              Generate Binder file
            </Button>
            {/* <Box sx={{ mt: 2, mr: 2, width: '40%' }}>
              <PdfPreviewPopup pdfUrl={'url'} label={`VIEW Binder File`} sx={{ width: '100%' }} />
            </Box> */}

            <Button
              sx={{
                backgroundColor: '#D9D9D9',
                px: 2,
                color: 'black',
                borderRadius: 5,
                width: '40%',
                // boxShadow: '0px 8px 10px #327B96',
                mt: 2,
                mr: 2,
              }}
              onClick={async () => {
                router.push(`/createCommercialInv/${id}`);
              }}
              style={{ textTransform: 'uppercase' }}
              variant="contained"
            >
              commercial Invoice mapping
            </Button>
            <Button
              sx={{
                backgroundColor: '#D9D9D9',
                px: 2,
                color: 'black',
                borderRadius: 5,
                width: '40%',
                // boxShadow: '0px 8px 10px #327B96',
                mt: 2,
                mr: 2,
              }}
              onClick={async () => {
                router.push(`/commercialInv-list/${id}`);
              }}
              style={{ textTransform: 'uppercase' }}
              variant="contained"
            >
              commercial Invoice List
            </Button>
            <ProofOfDeliveryModal row={response?.quote} podFilteredForRow={proofOfDelData}/> 

            <HasAccess
              roles={['admin']}
              permissions={['accounting_client_backend_level1_generate_proforma_invoice']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              

        <Button
              sx={{
                backgroundColor: '#D9D9D9',
                px: 2,
                color: 'black',
                borderRadius: 5,
                width: '40%',
                // boxShadow: '0px 8px 10px #327B96',
                mt: 2,
                mr: 2,
                textAlign:"center"
              }}
              href={`/generateProformaPdf/${id}`}
              style={{ textTransform: 'uppercase' }}
              variant="contained"
              disabled={!response?.quote?.AdminPaymentApproval}

            >
                {/* <Typography textTransform="uppercase" variant="button"> */}
                  Generate Proforma Invoice
                {/* </Typography> */}
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_client_backend_level1_admin_request']}
            >
              <LoadingButton
                sx={{
                  backgroundColor: '#D9D9D9',
                  px: 2,
                  color: 'black',
                  borderRadius: 5,
                  width: '40%',
                  // py:2
                  //boxShadow: '0px 8px 10px #327B96',
                  mt: 2,
                  mr: 2,
                }}
                style={{ textTransform: 'uppercase' }}
                loading={loading}
                variant="contained"
                onClick={() => {
                  onCompleteL2();
                  addNotification();
                }}
              >
                SEND TO ADMIN FOR APPROVAL
              </LoadingButton>
            </HasAccess>
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={onCompleteL2}
              sx={{
                backgroundColor: '#D9D9D9',
                px: 2,
                color: 'black',
                borderRadius: 5,
                width: '40%',
                // py:2
                //boxShadow: '0px 8px 10px #327B96',
                mt: 2,
                mr: 2,
              }}
            >
              MARK AS COMPLETE
            </LoadingButton>
            {/* <Stack sx={{ mb:2 }} direction={'row'} justifyContent={'space-between'}> */}
            <Stack>
            <UploadManualFiles 
              label="Manual Commercial Invoice"
              path="manual/commercialfile"
              value={response?.quote?.manualCommercialFile ? response?.quote?.manualCommercialFile : []}
              sx={{
                width: '80%', // Adjust width to ensure they fit within the available space
              }}
              setValue={(val) => updateManualFiles('commercial', val)}
              deleteSelectedFile={(filesName) => openDeleteConfirmationModal("commercial", filesName)}
            />
            </Stack>
            <Stack >
            <UploadManualFiles 
              label="Manual Binder File"
              path="manual/binder"
              value={response?.quote?.manualBinderFile ? response?.quote?.manualBinderFile : []}
              sx={{
                width: '80%', // Adjust width to ensure they fit within the available space
              }}
              setValue={(val) => updateManualFiles('binder', val)}
              deleteSelectedFile={(filesName) => openDeleteConfirmationModal("binder", filesName)}
            />
            </Stack>





            {/* <Box display="flex" sx={{ width: '100%', mb: 1 }}> */}
            {/* {backendAuth === true ? 
                  <UploadManualFiles 
                      label="INVOICE - EXT 1212"
                      path="manual/invoice"
                      value={response?.quote?.CommercialManualInvoice ? response?.quote?.CommercialManualInvoice : []}
                      // sx={{
                      //   backgroundColor: '#D9D9D9',
                      //   p: 2,
                      //   color: 'black',
                      //   borderRadius: 5,
                      //   width: '100%',
                      // }}
                      setValue={(val) => updateManualFiles('invoice', val)}
                      deleteSelectedFile={(filesName)=>openDeleteConfirmationModal("invoice", filesName)}
                    />:null} */}
                {/* </Box> */}
        </Box>
        </Box> : null
//           <Box
//           sx={{
//             width: '100%',
//             display: 'flex',
//             flexWrap: 'wrap',
//             justifyContent: 'space-between',
//           }}
//         >
//           <Button
//             variant="contained"
//             sx={{
//               color: 'black',
//               backgroundColor: '#D9D9D9',
//               px: 5,
//               borderRadius: 5,
//               width: '40%',
//               //  boxShadow: '0px 8px 10px #327B96',
//               mt: 2,
//             }}
//           >
//             Accounting
//           </Button>
//           <Button
//             variant="contained"
//             sx={{
//               color: 'black',
//               backgroundColor: '#D9D9D9',
//               px: 5,
//               borderRadius: 5,
//               width: '40%',
//               //  boxShadow: '0px 8px 10px #327B96',
//               mt: 2,
//             }}
//           >
//             View Ledger
//           </Button>
//           <HasAccess
//             roles={['admin']}
//             permissions={['accounting_client_backend_level1_view_commercial_invoice_pdf']}
//           >
//             <Button
//             variant="contained"
//             sx={{
//               color: 'black',
//               backgroundColor: '#D9D9D9',
//               // px: 5,
//               borderRadius: 5,
//               width: '40%',
//               //  boxShadow: '0px 8px 10px #327B96',
//               mt: 2,
//             }}
//           >
//             Commercial Invoice
//           </Button>
//           </HasAccess>
//           <Button
//             variant="contained"
//             sx={{
//               color: 'black',
//               backgroundColor: '#D9D9D9',
//               // px: 5,
//               borderRadius: 5,
//               width: '40%',
//               //  boxShadow: '0px 8px 10px #327B96',
//               mt: 2,
//             }}
//           >
//             Generate Binder File
//           </Button>
//           {/* <Box sx={{ mt: 2, mr: 2, width: '40%' }}>
//             <PdfPreviewPopup pdfUrl={'url'} label={`VIEW Binder File`} sx={{ width: '100%' }} />
//           </Box> */}
//  <Button
//             variant="contained"
//             sx={{
//               color: 'black',
//               backgroundColor: '#D9D9D9',
//               // px: 5,
//               borderRadius: 5,
//               width: '40%',
//               //  boxShadow: '0px 8px 10px #327B96',
//               mt: 2,
//             }}
//           >
//             Commercial Invoice Mapping
//           </Button>
//           <Button
//             variant="contained"
//             sx={{
//               color: 'black',
//               backgroundColor: '#D9D9D9',
//               // px: 5,
//               borderRadius: 5,
//               width: '40%',
//               //  boxShadow: '0px 8px 10px #327B96',
//               mt: 2,
//             }}
//           >
//             Commercial Invoice List
//           </Button>
//           <Button
//             variant="contained"
//             sx={{
//               color: 'black',
//               backgroundColor: '#D9D9D9',
//               // px: 5,
//               borderRadius: 5,
//               width: '40%',
//               //  boxShadow: '0px 8px 10px #327B96',
//               mt: 2,
//             }}
//           >
//             Proof of Delivery
//           </Button>
//           <HasAccess
//             roles={['admin']}
//             permissions={['accounting_client_backend_level1_generate_proforma_invoice']}
//             // renderAuthFailed={<p>You are not authorized to access!</p>}
//           >
            

//             <Button
//             variant="contained"
//             sx={{
//               color: 'black',
//               backgroundColor: '#D9D9D9',
//               // px: 5,
//               borderRadius: 5,
//               width: '40%',
//               //  boxShadow: '0px 8px 10px #327B96',
//               mt: 2,
//             }}
//           >
//             Generate Proforma Invoice
//           </Button>
//           </HasAccess>
//           <HasAccess
//             roles={['admin']}
//             permissions={['accounting_client_backend_level1_admin_request']}
//           >
//              <Button
//             variant="contained"
//             sx={{
//               color: 'black',
//               backgroundColor: '#D9D9D9',
//               // px: 5,
//               borderRadius: 5,
//               width: '40%',
//               //  boxShadow: '0px 8px 10px #327B96',
//               mt: 2,
//             }}
//           >
//             Send To Admin For Approval
//           </Button>
//           </HasAccess>
//           <Button
//             variant="contained"
//             sx={{
//               color: 'black',
//               backgroundColor: '#D9D9D9',
//               // px: 5,
//               borderRadius: 5,
//               width: '40%',
//               //  boxShadow: '0px 8px 10px #327B96',
//               mt: 2,
//             }}
//           >
//             MARK AS COMPLETE
//           </Button>

//           {/* {backendAuth === true ? 
//           <Box display="flex" sx={{ width: '40%', mb: 1 }}>
//                 <UploadManualFiles 
//                     label="INVOICE - EXT"
//                     path="manual/invoice"
//                     value={response?.quote?.CommercialManualInvoice ? response?.quote?.CommercialManualInvoice : []}
//                     sx={{
//                     //   backgroundColor: '#D9D9D9',
//                     //   // p: 2,
//                     //   color: 'black',
//                     //   borderRadius: 5,
//                     //   mt: 5,
//                       width: '100%',
//                     }}
//                     setValue={(val) => updateManualFiles('invoice', val)}
//                     deleteSelectedFile={(filesName)=>openDeleteConfirmationModal("invoice", filesName)}
//                   />
//               </Box>:null} */}
//         </Box>
        }
      </Box>
      {backendAuth === false ? 
      <Box sx={{ mb: 2 }}>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_client_backend_level2_admin_approve']}
            >
               {backendAuth === false ? 
              <Typography textTransform="uppercase" variant={'subtitle1'} sx={{ color: 'white' }}>
                Admin Actions
              </Typography>:null}

              <Divider sx={{ mb: 2 }} />
              {backendAuth === false ? 
              <Grid container spacing={2}>
                <Grid item>
                  <LoadingAsync
                    label={'Approve payment'}
                    loadFunc={AdminInitiatePaymentApprove}
                    handleResponse={(res) => {
                      setResponse({
                        ...response,
                        quote: { ...response.quote, InitiatePaymentApproval: true },
                      });
                    }}
                    handleErr={(err) => console.log(err)}
                    params={[id]}
                    buttonProps={{
                      disabled: response?.quote?.InitiatePaymentApproval,
                      variant: 'contained',
                    }}
                  />
                </Grid>
                {
                  response?.quote?.InitiatePaymentApproval &&
                  <Grid item>
                    <LoadingAsync
                      label={'Advance payment'}
                      loadFunc={AdminPaymentApprove}
                      handleResponse={(res) => {
                        setResponse({
                          ...response,
                          quote: { ...response.quote, AdminPaymentApproval: true },
                        });
                      }}
                      handleErr={(err) => console.log(err)}
                      params={[id]}
                      buttonProps={{
                        disabled: response?.quote?.AdminPaymentApproval,
                        variant: 'contained',
                      }}
                    />
                  </Grid>
                }
              </Grid>:
             null}
            </HasAccess>
          </Box>
        </Grid>
        {backendAuth === false ? 
        <>
        {response?.quote?.AdminPaymentApproval && response?.quote?.InitiatePaymentApproval ? (
          <>
            {response?.quote?.isadvancePaymentReceived || !response?.quote?.proformaInvoice[0] ? (
              <>
                <Box style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                  <TextField
                    style={{ marginRight: '10px' }}
                    id="percent"
                    label="Advance amount ( % )"
                    variant="outlined"
                    type="number"
                    error={advanceAmountPerError}
                    value={advanceAmountPer}
                    size="small"
                    disabled={
                      response?.quote?.advanceAdminApproval === false &&
                      response?.quote?.proformaInvoice[0]
                        ? true
                        : false
                    }
                    onChange={handleChange}
                    onKeyUp={(event) => getAmountValue(event)}
                    inputProps={{
                      pattern: '^(100|[1-9]?[0-9])$',
                      title: 'Enter a value between 0 and 100',
                    }}
                  />
                  <TextField
                    id="amount"
                    label="Advance ammount"
                    variant="outlined"
                    type="number"
                    size="small"
                    disabled
                    value={advanceAmount}
                    onChange={handleChange}
                  />
                  <Button
                    style={{ textTransform: 'uppercase' }}
                    variant="contained"
                    size="medium"
                    disabled={
                      (response?.quote?.advanceAdminApproval === false &&
                      response?.quote?.proformaInvoice[0]
                        ? true
                        : false) || advanceAmountPerError
                    }
                    sx={{ ml: 2 }}
                    onClick={handleRequestAdminApprove}
                  >
                    Request Admin Approval
                  </Button>
                </Box>
              </>
            ) : null}
            {!response?.quote?.isadvancePaymentReceived ? (
              <Box>
                {response?.quote?.advanceAdminApproval ||
                !response?.quote?.proformaInvoice[0] ? null : (
                  <HasAccess
                    roles={['admin']}
                    permissions={['accounting_approve_recent_proforma_button']}
                    renderAuthFailed={<p>Admin Approval For Recent Proforma Pending!!</p>}
                  >
                    <Button
                      style={{ textTransform: 'uppercase' }}
                      variant="contained"
                      sx={{ mt: 1, py: 2 }}
                      onClick={handleAdminApproveLatestProforma}
                    >
                      Approve recent Proforma Request (Admin)
                    </Button>
                  </HasAccess>
                )}
              </Box>
            ) : null}
            {!response?.quote?.isadvancePaymentReceived ? (
              response?.quote?.advanceAdminApproval ? (
                <Button
                  style={{ textTransform: 'uppercase' }}
                  variant="contained"
                  sx={{ mt: 1, py: 2 }}
                  onClick={handleLatestProformaReciveAmount}
                >
                  Approve Recent Advance Payment Recival (Accounting)
                </Button>
              ) : null
            ) : null}
          </>
        ) : null}</>
        :null}

        {showChip && (
            <Box
              sx={{
                marginTop: '10px',
                marginBottom: '10px',
              }}>
            <Chip
              textTransform="uppercase"
              label={`PROJECT ID : ${response?.quote?.projectId}`}
              style={{
                width: '23.2%',
                marginLeft:13,
                background: 'gray',
                color:'black',
                fontWeight:'bold'
              }}
            />
                </Box>
            )}
      </Box>: null}
      <Box display={'flex'} sx={{ mt: 2 }}>
        <HasAccess
          roles={['admin']}
          permissions={['accounting_frontend_view']}
          // renderAuthFailed={<p>You are not authorized to access!</p>}
        >
          <Button
            href={`/frontend-acc-client/${id}`}
            variant="contained"
            sx={{
              backgroundColor: 'gray',
              borderRadius: '0px',
              marginRight: '10px',
              ml:2,
              color:'black',
              borderTop: 'none'
            }}
          >
            Accounting
          </Button>
        </HasAccess>
        <HasAccess
          roles={['admin']}
          permissions={['accounting_client_backend_level1_view']}
          // renderAuthFailed={<p>You are not authorized to access!</p>}
        >
          <Button
            href={`/accounting-client-level1/${id}`}
            variant="contained"
            sx={{
              backgroundColor: 'gray',
              borderRadius: '0px',
              marginRight: '10px',
              color:'black',
              borderTop: 'none'
            }}
          >
            Accounting CLIENT L1
          </Button>
        </HasAccess>
        <HasAccess
          roles={['admin']}
          permissions={['accounting_client_backend_level2_view']}
          // renderAuthFailed={<p>You are not authorized to access!</p>}
        >
          <Button
            href={`/accounting-client-level2/${id}`}
            variant="contained"
            sx={{
              backgroundColor: 'gray',
              borderRadius: '0px',
              marginRight: '10px',
              color:'black',
              borderTop: '2px solid red',
            }}
          >
            Accounting CLIENT L2
          </Button>
        </HasAccess>
        {backendAuth === false ? 
            <>
        <HasAccess
          roles={['admin']}
          permissions={['accounting_vendor_backend_level1_view']}
          // renderAuthFailed={<p>You are not authorized to access!</p>}
        >
          <Button
            href={`/acc-vendor/l1/${id}`}
            variant="contained"
            sx={{
              backgroundColor: 'gray',
              borderRadius: '0px',
              marginRight: '10px',
              color:'black',
              borderTop: 'none'
            }}
          >
            Accounting Vendor L1
          </Button>
        </HasAccess>
        <HasAccess
          roles={['admin']}
          permissions={['accounting_vendor_backend_level2_view']}
          // renderAuthFailed={<p>You are not authorized to access!</p>}
        >
          <Button
            href={`/acc-vendor/l2/${id}`}
            variant="contained"
            sx={{
              backgroundColor: 'gray',
              borderRadius: '0px',
              marginRight: '10px',
              color:'black',
              borderTop: 'none'
            }}
          >
            Accounting Vendor L2
          </Button>
        </HasAccess>
        </>
            : null}
        {backendAuth === false ? 
          <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['vendor_home_default_view']}>
            <Button
              disabled={response?.quote?.isCancelled}
              onClick={() => router.push(`/quote/${id}/vendor`)}
            variant="contained"
            sx={{
              backgroundColor: 'gray',
              borderRadius: '0px',
              marginRight: '10px',
              color: 'black'
            }}
            >
              Vendor HomePage
            </Button>
          </HasAccess>: null}
        <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['quote_backend_page_2_default_view']}>
            <Button
              onClick={() => router.push(`/quote/l2/${id}`)}
              variant="contained"
              sx={{
                backgroundColor: 'gray',
                borderRadius: '0px',
                marginRight: '10px',
                color:'black',
                borderTop: 'none'
              }}>
              QUOTE L2
            </Button>
          </HasAccess>
            <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['purchase_order_backend_level2_view']}>
            <Button
              onClick={() => router.push(`/poStage/purchaseOrderl2/${id}`)}
              variant="contained"
              sx={{
                backgroundColor: 'gray',
                borderRadius: '0px',
                marginRight: '10px',
                color:'black',
                borderTop: 'none'
              }}
            >
              PO L2
            </Button>
          </HasAccess>
          {backendAuth === false ?
          <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['delivery_l2']}>
            <Button
              href={`/delivery/deliveryl2/${id}`}
              sx={{
                backgroundColor: 'gray',
                borderRadius: '0px',
                marginRight: '10px',
              }}
            >
              Delivery
            </Button>
          </HasAccess>
          :null}
         
      </Box>

      <AccClientLevel2Table response={response?.quote} setRefresh={setRefresh} refresh={refresh} />
    </Container>
  );
}
