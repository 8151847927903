export const calculateContractAmount = (data) => {
  let Total = 0;
  data?.line_items?.map((i) => {
    Total = Total + (calculateClientUnitPrice(i, data) * (i?.quantity ? i?.quantity:0));
  });
  return Total;
};

export const calculateDiscountAmount = (data) => {
  return calculateContractAmount(data) * ((data?.addondiscountrate || 0) / 100);
};
export const calculateTaxAmount = (data) => {
  return (
    (calculateContractAmount(data)+ data?.addonshippingHandling+ data?.airFreight - calculateDiscountAmount(data)) *
    ((data?.addontaxrate || 0) / 100)
  );
};
export const calculateFinalAmount = (data) => {
  return (
    calculateContractAmount(data) -
    calculateDiscountAmount(data) +
    calculateTaxAmount(data) +
    data?.addonshippingHandling+ data?.airFreight
  );
};
export const calculateBenifit = (item, data) => {
  return item.targetPrice && data.benifit ? (item.targetPrice * data.benifit) / 100 : 0;
};

export const calculateShipping = (item, data) => {
  return item.targetPrice && data.shippingAndHandling && data.benifit
    ? ((Number(item.targetPrice) + calculateBenifit(item, data)) * data.shippingAndHandling) / 100
    : 0;
};

export const calculateClientUnitPrice = (item, data) => {
  return calculateBenifit(item, data) + calculateShipping(item, data) + Number(item.targetPrice ? item.targetPrice:0);
};
